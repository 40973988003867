<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert" 
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form 
              ref="form" 
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.title"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('CONTACT_US.ADD_EDIT.TITLE')"
                    :rules="form.titleRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                   <label>{{ $t('CONTACT_US.ADD_EDIT.DESCRIPTION_TH') }}</label>
                    <editor
                    api-key="no-api-key"
                    :init="{
                        height: 500,
                        menubar: false,
                        content_css : 'dark',
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        'undo redo | formatselect | fontsizeselect bold italic forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                    v-model="form.descriptionTH"
                    />
                </v-col>

                <v-col cols="12" md="12">
                  <label>{{ $t('CONTACT_US.ADD_EDIT.DESCRIPTION_EN') }}</label>
                    <editor
                    api-key="no-api-key"
                    :init="{
                        height: 500,
                        menubar: false,
                        content_css : 'dark',
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        'undo redo | formatselect | fontsizeselect bold italic forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    
                    }"
                    v-model="form.descriptionEN"
                    />
                </v-col>

                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('CONTACT_US.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("CONTACT_US.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}
                    </v-card-title
                  >
                  <v-card-text>
                      {{form.message}}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="closeDialog"
                      >{{ $t("SHARED.CLOSE_BUTTON") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
    'editor': Editor
  },
  data() {
    return {
      form: {
        valid: true,
        dialog: false,
        loading: false,
        typeLoading: true,
        errors: [],

        contactUsId: null,
        title: "",
        descriptionTH: null,
        descriptionEN: null,
        inActiveStatus: null,
        
        message: null,

        titleRules: [
          (v) => 
          !!v || this.$t("CONTACT_US.ADD_EDIT.TITLE_VALIDATION"),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("CONTACT_US.ADD_EDIT.TITLE_COUNTER"),
        ],
      }
    }
  },
  computed: {
    title() {
      return this.$t("MENU.CONTACT_US.EDIT");
    }
  },
  watch: {

  },
  methods: {
    submitForm() {
      this.form.errors = [];

      if(this.form.descriptionTH == null || this.form.descriptionTH == ""){
        this.form.errors.push(
          this.$t("CONTACT_US.ADD_EDIT.DESCRIPTION_TH_VALIDATION")
        );
      }

      if(this.form.descriptionEN == null || this.form.descriptionEN == ""){
        this.form.errors.push(
          this.$t("CONTACT_US.ADD_EDIT.DESCRIPTION_EN_VALIDATION")
        );
      }

      if(this.$refs.form.validate() && this.form.errors.length == 0)
      {
        this.postDataToApi();
      }
    },
    resetForm() {
      this.form.descriptionTH = "";
      this.form.descriptionEN = "";
      this.$refs.form.reset();
    },
    postDataToApi() {
      this.form.loading = true;
      this.form.errors = [];

      ApiService.setHeader();
      ApiService.put("/Api/ContactUs/Edit", 
      {
        contactUsId: this.form.contactUsId,
        Title: this.form.title,
        DescriptionTH: this.form.descriptionTH,
        DescriptionEN: this.form.descriptionEN,
        InActiveStatus: !this.form.inActiveStatus
      })
      .then(({ data }) => {
        if (data.status) {
          // close dialog
          this.form.message = data.message
          this.form.dialog = true;
        } else {
          this.form.dialog = false;
          this.form.loading = false;
          this.form.errors.push(!!data.message || "Unknow error occurs");
          this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
        }
      })
      .catch(({ response }) => {
        if (response.data) {
          this.form.errors.push(response.data.message);
        } else {
          this.form.errors.push("Unknow error occurs");
        }
        this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
        this.form.dialog = false;
        this.form.loading = false;
      });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchContactUs" });
    },
    getDataFromApi(id) {
      this.form.loading = true;
      return new Promise(() => {
        ApiService.setHeader();
        ApiService.get("/Api/ContactUs", id)
          .then(({ data }) => {
            this.form.contactUsId = data.contactUsId;
            this.form.title = data.title;
            this.form.descriptionTH = data.descriptionTH;
            this.form.descriptionEN = data.descriptionEN;
            this.form.inActiveStatus = !data.inActiveStatus;
          })
          .finally(() => {
            this.form.loading = false;
          });
      });
    },
    getTypeFromApi() {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/ContactUs/Select2TermandPrivacyType", {
          query: null,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.typeLoading = false;
          });
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.CONTACT_US.SECTION"), route: "/ContactUs" },
      { title: this.$t("MENU.CONTACT_US.EDIT") },
    ]);

    this.getDataFromApi(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
